/* prettier-ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles/fontawesome/all.css';

button.tw,
input.tw,
optgroup.tw,
select.tw,
textarea.tw {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

button.tw,
select.tw {
    text-transform: none;
}

button.tw,
[type='button'].tw,
[type='reset'].tw,
[type='submit'].tw {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
}
